import ua from "../../plugins/userAgent.js";

//電話番号スマホのみ掛ける
const targets = document.querySelectorAll("a[href*='tel:']");

targets.forEach((target) => {
    target.addEventListener("click", (e) => {
        if (ua.getDevice() == "pc") {
            e.preventDefault();
        }
    });
});