import { swiperRegister, Swiper } from "@ml/swiper/swiper";

swiperRegister();

const swiperType1 = Swiper({
    el: "swiper-container.swiper-slide-type-1",
    options: {
        slidesPerView: 1.5,
        spaceBetween: 24,
        centeredSlides: true,
        speed: 500,
        loop: true,
        pagination: {
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2.5,
            },
        },
        // autoplay: {
        //     delay: 3000,
        // },
    },
});
