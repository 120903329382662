document.addEventListener("DOMContentLoaded", function () {
    const openBtns = document.querySelectorAll(".js-modal-open");
    const closeBtns = document.querySelectorAll(".js-modal-close-button");

    //開く
    openBtns.forEach(function(btn){
        btn.addEventListener('click', function(){
            let modalId = btn.dataset.modal;
            let targetModalWindow = document.getElementById(modalId);
            targetModalWindow.style.display = "block";
        });
    });

    //閉じる｜ボタン
    closeBtns.forEach(function (btn) {
        btn.addEventListener('click', function(){
            let targetModalWindow = btn.closest('.js-modal');
            targetModalWindow.style.display = "none";
        });
    });

    //閉じる｜モーダル外
    window.addEventListener('click', function(e){
        if (event.target.className === "js-modal") {
            event.target.style.display = "none";
        }
    });

});
