// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements

export const swiperRegister = () => {
    register();
};

export const Swiper = ({ el, options = {}, ...props }) => {
    const swiperElements = document.querySelectorAll(el);
    const swiperParamenters = Object.assign({}, options);

    swiperElements.forEach((swiperEl, _) => {
        Object.assign(swiperEl, swiperParamenters);
        swiperEl.initialize();
    });

    return swiperElements;
};
