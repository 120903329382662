document.addEventListener("DOMContentLoaded", function () {

  document.querySelectorAll(".js-product-item").forEach((item,index) => {
    item.addEventListener('mouseleave', () => {
      document.querySelectorAll(".js-product-item")[0].style.opacity = 1;
    });


    item.addEventListener("mouseenter", () => {
      if(index !== 0 ){
        document.querySelectorAll(".js-product-item")[0].style.opacity = 0.2;
      }

      const imageSrc = "/assets/img/top/" + item.getAttribute("data-image");
      const imageSrc_Detail = imageSrc.replace(".png","_detail.png");

      const imgWrapper_Left = document.querySelectorAll(".p-top-products__img.--left")[0]
      const imgWrapper_Right = document.querySelectorAll(".p-top-products__img.--right")[0]

      const cloneLeft = imgWrapper_Left.cloneNode(true);
      const cloneRight = imgWrapper_Right.cloneNode(true);
      cloneLeft.classList.remove('is-fadeInUp','js-fadeInUp');
      cloneRight.classList.remove('is-fadeInUp','js-fadeInUp');

      imgWrapper_Left.before(cloneLeft)
      imgWrapper_Right.before(cloneRight)

      setTimeout(imageChange,0);
      setTimeout(function(){imgWrapper_Left.remove()},600);
      setTimeout(function(){imgWrapper_Right.remove()},600);


      function imageChange() {
       cloneLeft.children[0].src = imageSrc;
       cloneRight.children[0].src = imageSrc_Detail;

       imgWrapper_Left.animate({opacity: [1, 0]},  {fill: "forwards",duration: 500})
       imgWrapper_Right.animate({opacity: [1, 0]},  {fill: "forwards",duration: 500})
     }
   });
  });

});

if (top) {
  document.addEventListener("DOMContentLoaded", function () {
// フェード
    const fadeInElements = [
    {
      elements: document.querySelectorAll(".js-fadeInUp"),
      className: "is-fadeInUp",
    },
    {
      elements: document.querySelectorAll(".js-fadeInRightToLeft"),
      className: "is-fadeInRightToLeft",
    },
    {
      elements: document.querySelectorAll(".js-fadeInLeftToRight"),
      className: "is-fadeInLeftToRight",
    },
    {
      elements: document.querySelectorAll(".js-fadeIn"),
      className: "is-fadeIn",
    },
    ];

    const fadeInTextElements = document.querySelectorAll(".p-top-fv__title, .p-top-beActive__title, .p-top-beAssemble__title, .p-top-beGlobal__fv-title");

// 文字を一文字ずつ <span> タグで囲む
    function wrapTextNodesWithSpans(node) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
        const parent = node.parentNode;
        const text = node.nodeValue;
        const chars = [...text].map((char) => {
          const span = document.createElement("span");
          span.className = "is-fade-in-char";
          span.textContent = char;
          return span;
        });
        chars.forEach((span) => parent.insertBefore(span, node));
        parent.removeChild(node);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(wrapTextNodesWithSpans);
      }
    }

// フェードインアニメーションの可視性チェック
    function checkVisibility() {
      fadeInElements.forEach((group) => {
        group.elements.forEach((element) => {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            element.classList.add(group.className);
          }
        });
      });

      fadeInTextElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          if (!element.classList.contains("is-visible")) {
            element.classList.add("is-visible");
            const charSpans = element.querySelectorAll(".is-fade-in-char");
            charSpans.forEach((span, index) => {
              span.style.animationDelay = `${index * 0.05}s`;
              span.style.animationPlayState = "running";
            });
          }
        }
      });
    }

// 初回表示時に一文字ずつ表示されるように文字を分割
    fadeInTextElements.forEach(wrapTextNodesWithSpans);

// 初回チェック
    checkVisibility();

// スクロールイベントでチェック
    window.addEventListener("scroll", checkVisibility);





//アニメーションの速度を計算してCSS変数に
    function calculateLoopAnimationSpeed() {
      const targets = document.querySelectorAll(".js-tick");
      if (!targets.length) {
        return;
      }

      const distance = window.innerWidth;
      const mql = window.matchMedia("(min-width: 801px)");
      const time = mql.matches ? 18 : 9; //ここで時間を調整
      const speed = distance / time;

      targets.forEach((target) => {
        const tickElems = target.querySelectorAll(".js-tick-item");
        if (!tickElems.length) {
          return;
        }

        const total = tickElems.length - 1;

        tickElems.forEach((el, i) => {
          const elWidth = el.clientWidth;
          const elTime = Math.floor(elWidth / speed);
          el.style.setProperty("--tick-duration", `${elTime}s`);
          el.style.setProperty("--tick-delay", `${elTime / -2}s`);
        });
      });
    }
  });
}
