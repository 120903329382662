import Tab from "../../plugins/tab.js";
const elements = document.querySelectorAll(".js-tab");
elements.forEach((element) => {
  new Tab(element, {
    defaultOpenPanel: 0,
    // tabWrapper: ".js-tab-list",
    // tabs: ".js-tab-button",
    // panelWrapper: ".js-tab-panel-wrapper",
    // panels: ".js-tab-panel",
    // defaultOpenPanel: 0
  });
});