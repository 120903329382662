if (window.location.pathname === "/") {
  document.querySelector(".l-header-logo img").src = "/assets/img/common/logo_black.svg";
  
} else{
  // スクロール時の処理を設定
  window.addEventListener("scroll", function () {
    let headerElement = document.querySelector(".l-header");
    let fvElement = document.querySelector(".l-fv");

    // #js-top-informationが表示されているかを確認
    if (window.scrollY > fvElement.offsetHeight / 3) {
      headerElement.classList.add("is-scrolled");
      // 画像のsrcを切り替える
      document.querySelector(".l-header-logo img").src = "/assets/img/common/logo_black.svg";
    } else {
      headerElement.classList.remove("is-scrolled");
      // 画像のsrcを元に戻す
      document.querySelector(".l-header-logo img").src = "/assets/img/common/logo_white.svg";
    }
  });
}